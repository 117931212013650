<template>
  <Delete
    action-handler="rms-rate-plans/deletePlan"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
  "en": {
    "title": "Delete Rate Plan?",
    "desc": "Are you use you want to delete <strong>{name}</strong> rate plan?",
    "deleted-title": "Rate plan deleted",
    "deleted-desc": "Rate plan <strong>{name}</strong> has been removed."
  },
  "ja": {
    "title": "プランを削除しますか？",
    "desc": "本当にこの<strong>{name}</strong>プランを削除してもよろしいですか？",
    "deleted-title": "プラン削除完了",
    "deleted-desc": "プラン<strong>{name}</strong>が削除されました"
  }
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteRatePlan',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
