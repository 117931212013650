<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ value && value.id ? $t('Edit Rate Plan') : $t('Add Rate Plan') }}
      </h3>
    </div>

    <a-form-item
      class="column is-12"
      :label="$t('Name')"
      name="name"
    >
      <a-input
        v-model:value="form.name"
        :size="size"
        type="text"
      />
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('Code')"
      name="code"
    >
      <InputPlan v-model:value="form.code" />
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('Code')"
      name="code"
    >
      <InputCalendarId
        v-model:value="form.neppanCode"
        :property-id="property?.newPropertyCode"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/rate-plans.json"></i18n>
<i18n>
{
  "en": {
    "Add Rate Plan": "Add Rate Plan",
    "Edit Rate Plan": "Edit Rate Plan",
    "Name is required": "Name is required",
    "Code is required": "Code is required",
    "Neppan code is required": "Neppan code is required",
  },
  "ja": {
    "Add Rate Plan": "プランを追加",
    "Edit Rate Plan": "プランを編集",
    "Name is required": "プラン名は必須項目です",
    "Code is required": "Code is required",
    "Neppan code is required": "Neppan code is required",
  }
}
</i18n>

<script>
import { RATE_PLANS } from '@/config/revenue-managements';
import InputPlan from '@/views/revenue-management/rates/components/InputPlan';
import InputCalendarId from '@/views/revenue-management/rates/components/InputCalendarId';

export default {
  name: 'RatePlanForm',
  components: {
    InputPlan,
    InputCalendarId,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      planOptions: RATE_PLANS,
      form: {
        name: undefined,
        code: undefined,
        neppanCode: undefined,
      },
      size: 'large',
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('Name is required'),
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: this.$t('Code is required'),
            trigger: ['blur', 'change'],
          },
        ],
        neppanCode: [
          {
            required: true,
            message: this.$t('Neppan code is required'),
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const payload = {
          ...this.form,
          propertyId: this.property.newPropertyCode,
        };

        if (this.value && this.value.id) {
          payload.id = this.value.id;

          await this.$store.dispatch('rms-rate-plans/update', payload);
        } else {
          await this.$store.dispatch('rms-rate-plans/add', payload);
        }

        this.$refs.form.resetFields();
        this.loading = false;
        this.$emit('close');
      } catch (e) {
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped></style>
